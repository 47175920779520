// import { UserRole } from '../../shared/shared.interface';
import { jwtDecode } from 'jwt-decode';
// const useUserPermissions = (jwtToken:string) => {
//     if (!jwtToken)
//         return [ 'read:issue' ];

//     const jwtData = jwtToken.split('.')[1];
//     const decodedJwtJsonData = window.atob(jwtData);
//     const decodedJwtData = JSON.parse(decodedJwtJsonData);
//     const assignedPermissions = decodedJwtData.permissions ? decodedJwtData.permissions : [ 'read:issue' ];
//     return assignedPermissions;
// };

export const useIsAuthenticated = () => {
    const token = localStorage.getItem('authToken');

    if (token)
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken?.exp < currentTime) {
                localStorage.removeItem('authToken');
                return false;
            }
        } catch (error) {
            // Handle token decoding error
            localStorage.removeItem('authToken');
            return false;
        }

    return !!token;
};

export const useAuthToken = () => {
    const token = localStorage.getItem('authToken');

    if (token)
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken?.exp < currentTime) {
                localStorage.removeItem('authToken');
                return null;
            }
        } catch (error) {
            // Handle token decoding error
            localStorage.removeItem('authToken');
            return null;
        }

    return token;
};

// const useUserRoles = () => {
//     // if (!jwtToken)
//     //     return [ UserRole.GUEST ];

//     // const jwtData = jwtToken.split('.')[1];
//     // const decodedJwtJsonData = window.atob(jwtData);
//     // const decodedJwtData = JSON.parse(decodedJwtJsonData);
//     // const assignedRoles = decodedJwtData.roleTypes ? decodedJwtData.roleTypes : [ 'Guest' ];
//     return [ 'Admin' ];
// };

export const useIsAdminUser = () => {
    return true;
    // const assignedUserRoles:string[] = await useUserRoles(jwtToken);
    // if (assignedUserRoles == null || !assignedUserRoles.length)
    //     return false;

    // const userRole = assignedUserRoles.filter(element => element === UserRole.ADMIN);
    // return (userRole.length > 0);
};
